import React, { useState, useEffect } from "react";
import { styled, type Theme, type CSSObject } from "@mui/material/styles";
import {
  Box,
  Divider,
  Avatar,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import MapIcon from "@mui/icons-material/Map";
import RoomIcon from "@mui/icons-material/Room";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { signOut } from "firebase/auth";
import { auth } from "src/firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { callAPI } from "src/content/auth/AshiraseAPIProvider";
import { useToken } from "src/content/auth/TokenProvider";

const drawerWidth = 290;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open === true && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(open === false && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

function Sidebar(): JSX.Element {
  const [iconData, setIconData] = useState<any>(null);
  const [open, setOpen] = React.useState(false);
  const [logoutDialogOpen, setLogoutDialogOpen] = useState(false);
  const [navigationDialogOpen, setNavigationDialogOpen] = useState(false);
  const [pendingNavigationPath, setPendingNavigationPath] = useState<
    string | null
  >(null);
  const { apiRequest } = callAPI();
  const navigate = useNavigate();
  const location = useLocation();
  const { user, userData } = useToken();

  // 正規表現で/route/{routeId}を検出
  const isRoutePage = /\/route\/[^/]+/.test(location.pathname);

  // メニューを開く
  const handleDrawerOpen = (): void => {
    setOpen(true);
  };

  // メニューを閉じる
  const handleDrawerClose = (): void => {
    setOpen(false);
  };

  // ログアウト
  const handleLogout = async (): Promise<void> => {
    try {
      await signOut(auth);
      navigate("/login");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  // ログアウト確認ダイアログを開く
  const handleLogoutClick = (): void => {
    setLogoutDialogOpen(true);
  };

  const handleLogoutDialogClose = (confirmed: boolean): void => {
    setLogoutDialogOpen(false);
    if (confirmed) {
      handleLogout().catch(console.error);
    }
  };

  // ナビゲーション確認ダイアログを開く
  const handleNavigationClick = (path: string): void => {
    if (isRoutePage) {
      setPendingNavigationPath(path);
      setNavigationDialogOpen(true);
    } else {
      navigate(path);
    }
  };

  // ダイアログを閉じる
  const handleNavigationDialogClose = (confirmed: boolean): void => {
    setNavigationDialogOpen(false);
    if (confirmed && pendingNavigationPath !== null) {
      navigate(pendingNavigationPath);
    }
    setPendingNavigationPath(null);
  };

  useEffect(() => {
    const fetchIconData = async (): Promise<void> => {
      if (typeof userData?.icon === "string" && userData.icon !== "") {
        try {
          const iconUrl = userData.icon.replace(
            /^https?:\/\/[^/]+/,
            "",
          ) as string;
          if (typeof iconUrl === "string") {
            const response = await apiRequest(iconUrl);
            const blob = await response.blob();
            const blobUrl = URL.createObjectURL(blob);
            setIconData(blobUrl);
          }
        } catch (error) {
          console.error("Error fetching additional data:", error);
        }
      }
    };
    void fetchIconData();
  }, [userData]);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          {open ? (
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          ) : (
            <IconButton onClick={handleDrawerOpen} sx={{ padding: 1.8 }}>
              <MenuIcon />
            </IconButton>
          )}
        </DrawerHeader>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            paddingLeft: 2,
            paddingTop: 2,
            paddingBottom: 3,
            paddingRight: 2,
          }}
        >
          <Avatar
            src={iconData}
            alt={userData?.name ?? "未設定"}
            sx={{ marginRight: 2 }}
          />
          {open && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Typography variant="h6" noWrap>
                {userData?.name ?? "未設定"}
              </Typography>
              <Typography variant="caption" noWrap>
                {user?.email}
              </Typography>
            </Box>
          )}
        </Box>
        <Divider />

        <List>
          {/* ユーザアカウント */}
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                handleNavigationClick("/user");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <AccountBoxIcon />
              </ListItemIcon>
              <ListItemText
                primary="ユーザアカウント"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          {/* マイルート */}
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                handleNavigationClick("/my-route");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <MapIcon />
              </ListItemIcon>
              <ListItemText
                primary="マイルート"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>

          {/* お気に入り */}
          <ListItem disablePadding sx={{ display: "block" }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? "initial" : "center",
                px: 2.5,
              }}
              onClick={() => {
                handleNavigationClick("/top");
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : "auto",
                  justifyContent: "center",
                }}
              >
                <RoomIcon />
              </ListItemIcon>
              <ListItemText
                primary="お気に入り地点"
                sx={{ opacity: open ? 1 : 0 }}
              />
            </ListItemButton>
          </ListItem>
        </List>
        <Box sx={{ flexGrow: 1 }} />
        <Divider />
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
            onClick={handleLogoutClick}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <ExitToAppIcon />
            </ListItemIcon>
            <ListItemText primary="ログアウト" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </Drawer>

      <Dialog
        open={logoutDialogOpen}
        onClose={() => {
          handleLogoutDialogClose(false);
        }}
      >
        <DialogTitle>ログアウト確認</DialogTitle>
        <DialogContent>
          <DialogContentText>ログアウトしますか？</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleLogoutDialogClose(false);
            }}
          >
            キャンセル
          </Button>
          <Button
            onClick={() => {
              handleLogoutDialogClose(true);
            }}
            color="primary"
          >
            ログアウト
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={navigationDialogOpen}
        onClose={() => {
          handleNavigationDialogClose(false);
        }}
      >
        <DialogTitle>画面移動確認</DialogTitle>
        <DialogContent>
          <DialogContentText>この画面から離れますか？</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              handleNavigationDialogClose(false);
            }}
          >
            キャンセル
          </Button>
          <Button
            onClick={() => {
              handleNavigationDialogClose(true);
            }}
            color="primary"
          >
            移動
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default Sidebar;
