import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  useCallback,
} from "react";
import { auth } from "src/firebase";
import { onAuthStateChanged, signOut, type User } from "firebase/auth";
import { useNavigate } from "react-router-dom";

interface TokenContextType {
  token: string | null;
  refreshToken: () => Promise<void>;
  fetchToken: () => Promise<void>;
  isTokenReady: boolean;
  user: User | null;
  userData: any;
}

const TokenContext = createContext<TokenContextType | undefined>(undefined);

type TokenProviderProps = React.PropsWithChildren<Record<string, unknown>>;

export const TokenProvider = ({
  children,
}: TokenProviderProps): JSX.Element => {
  const [token, setToken] = useState<string | null>(null);
  const [isTokenReady, setIsTokenReady] = useState<boolean>(false);
  const [user, setUser] = useState<User | null>(null);
  const [userData, setUserData] = useState<any>(null);
  const navigate = useNavigate();

  const refreshToken = useCallback(async (): Promise<void> => {
    const currentUser = auth.currentUser;
    if (currentUser !== null) {
      const newToken = await currentUser.getIdToken(true);
      setToken(newToken);
      localStorage.setItem("token", newToken);
    } else {
      throw new Error("User not authenticated");
    }
  }, []);

  const fetchToken = useCallback(async (): Promise<void> => {
    const currentUser = auth.currentUser;
    if (currentUser !== null) {
      const newToken = await currentUser.getIdToken();
      setToken(newToken);
      localStorage.setItem("token", newToken);
    }
  }, []);

  useEffect(() => {
    const handleAuthStateChanged = (currentUser: User | null): void => {
      const updateUserState = async (): Promise<void> => {
        setUser(currentUser);
        if (currentUser !== null) {
          const newToken = await currentUser.getIdToken();
          setToken(newToken);
          localStorage.setItem("token", newToken);
          localStorage.setItem("user", JSON.stringify(currentUser));
          const BASE_URL = process.env.REACT_APP_ASHIRASE_API_DOMAIN;
          const endpoint = `/user/${currentUser.uid}`;
          const url = `${BASE_URL}${endpoint}`;
          const response = await fetch(url, {
            headers: {
              Authorization: `Bearer ${newToken}`,
            },
          });
          const result = await response.json();
          if (result.user.is_developer === false) {
            await signOut(auth);
            navigate("/login");
            setIsTokenReady(true);
          } else {
            setUserData(result.user);
            setIsTokenReady(true);
          }
        } else {
          setToken(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          setUserData(null);
          setIsTokenReady(true);
        }
      };
      void updateUserState();
    };

    const unsubscribe = onAuthStateChanged(auth, handleAuthStateChanged);

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <TokenContext.Provider
      value={{ token, refreshToken, fetchToken, isTokenReady, user, userData }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export const useToken = (): TokenContextType => {
  const context = useContext(TokenContext);
  if (context === undefined) {
    throw new Error("useToken must be used within a TokenProvider");
  }
  return context;
};
