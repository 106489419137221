import * as React from "react";
import { Button, CssBaseline, styled } from "@mui/material";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { auth } from "src/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";
import { useToken } from "../TokenProvider";

export default function SignIn(): JSX.Element {
  const MainContent = styled(Box)(
    ({ theme }) => `
        min-height: 100vh;
        display: flex;
        flex: 1;
        overflow: auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #e3dede;
    `,
  );

  const navigate = useNavigate();
  const { fetchToken } = useToken();

  const Login = async (email: string, password: string): Promise<void> => {
    await signInWithEmailAndPassword(auth, email, password)
      .then(async (userCredential) => {
        await fetchToken();
        navigate("/my-route");
      })
      .catch((error) => {
        alert(error.message);
        console.error(error);
      });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get("email") as string;
    const password = data.get("password") as string;
    void handleLogin(email, password);
  };

  const handleLogin = async (
    email: string,
    password: string,
  ): Promise<void> => {
    try {
      await Login(email, password);
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <MainContent>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            bgcolor: "white",
            boxShadow: 20,
            borderRadius: 2,
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box sx={{ mt: 3 }}>
            <img src="/static/images/status/logo256.svg" height={100} />
          </Box>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <Box
            component="form"
            onSubmit={handleSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
          </Box>
        </Box>
      </Container>
    </MainContent>
  );
}
