import React, { useState } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { themeCreator } from "./base";

export const ThemeContext = React.createContext(
  (themeName: string): void => {},
);

const ThemeProviderWrapper: any = (props: any) => {
  const curThemeName = localStorage.getItem("appTheme") ?? "PureLightTheme";
  const [themeName, _setThemeName] = useState(curThemeName);
  const theme = createTheme(themeCreator(themeName));
  const setThemeName = (themeName: string): void => {
    localStorage.setItem("appTheme", themeName);
    _setThemeName(themeName);
  };

  return (
    <ThemeContext.Provider value={setThemeName}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
};

export default ThemeProviderWrapper;
